import { useEffect } from "react";
import {  useNavigate } from "react-router-dom";

export function Error401Interceptor() {
  const history = useNavigate();

  useEffect(() => {
    const handle401Error = (error: any) => {
      if (error.response.status === 401) {
        const removeParamsTime = (url: string ): string => {
          const urlObj = new URL(url);
          urlObj.searchParams.delete('t');
          return urlObj.toString();
        }
        const newUrl = removeParamsTime(document.location.href);
        useNavigate()(newUrl);
      }
    };
  }, [history]);

  return null;
}
