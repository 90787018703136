import { INuevoPlayerVideojs } from "../components/Player/Player";
import { IPlayerConfig } from "../models/player-config";
import { getCurrentVideo } from "./player-ready";

const TRACKING_EVENT_PLAYER_KEY = 'ik-tracking-event-player';
const GET_EVENT_PLAYER_KEY = 'getPlayerEvent'

export interface ITrackerEventObject {
  play?: boolean;
  pause?: boolean;
  currentTime?: string;
  videoDuration?: string;
  ended?: boolean;
  seeking?: boolean;
  seekingFrom?: number;
  seekedFrom?: number | null;
  seekingTo?: number | null;
  seeked?: boolean;
  seekedTo?: number | null;
  state?: string;
  delta?: number;
  type?: string;
}

/**
 * Event list available from the player via postMessage
 *
 * getPlayerState = {state : 'play' | 'pause' | 'ended}
 * getCurrentVideoTimeAndDuration = {currentTime, videoDuration}
 * getSeekingEvent = {seekingFrom, seekingTo, delta }
 * getSeekedEvent = {seekedFrom, seekedTo, delta}
 *
 * @param player
 * @param config
 * @constructor
 */

export function SetPlayerTrackerEvents(player: INuevoPlayerVideojs, config: IPlayerConfig) {

  let previousTime = 0;
  let currentTime = 0;
  let seekStart: number | null = null;


  const eventTrackerPlayer = (obj: ITrackerEventObject): void => {
    const body = JSON.parse(
      JSON.stringify(
        Object.assign(
          {
            type: TRACKING_EVENT_PLAYER_KEY
          },
          obj
        )
      )
    );

    window.parent.postMessage(body, "*");
  }


  // play event tracker

  player.on("play", function () {
    eventTrackerPlayer({
      play: true,
      state: 'play',
      type: 'play'
    });
  });

  player.on("pause", function () {
    eventTrackerPlayer({
      pause: true,
      state: 'pause',
      type: 'pause'
    });
  });

  player.on('timeupdate', () => {
    const getCurrentVideoDuration = () => {
      if (config.media.length > 1) {
        return getCurrentVideo("playlist", player, config).duration;
      } else {
        return getCurrentVideo("single", player, config).duration;
      }
    }

    previousTime = currentTime;
    currentTime = player.currentTime();

    eventTrackerPlayer({
      currentTime: currentTime.toString(),
      videoDuration: getCurrentVideoDuration(),
      type: 'timeupdate'
    });
  })

  player.on('ended', () => {
    eventTrackerPlayer({
      ended: true,
      state: 'ended',
      type: 'ended'
    });
  })


  player.on('seeking', () => {
    if (seekStart === null) {
      seekStart = previousTime;
    }

    eventTrackerPlayer({
      seekingFrom: previousTime,
      seekingTo: currentTime,
      delta: currentTime - previousTime,
      type: 'seeking'
    })

  });

  player.on('seeked', () => {
    eventTrackerPlayer({
      seekedFrom: seekStart,
      seekedTo: currentTime,
      delta: currentTime - previousTime,
      type: 'seeked'
    });
    seekStart = null;
  });

}
