import dompurify from "dompurify";
import { Component } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "videojs-vr";
import "../../assets/nuevo_component/hotkeys";
import "../../assets/nuevo_component/nuevo";
import "../../assets/nuevo_component/playlist";
import "../../assets/nuevo_component/visualizer";
import "../../assets/nuevo_component/vroll";
import { storageAvailable } from "../../helpers/common";
import { INuevoOptions } from "../../models/nuevo-options";
import {
  IMedia,
  INuevoMedia,
  IPlayerAds,
  IPlayerConfig,
  IVastAds,
} from "../../models/player-config";
import nuevoOptions from "./config/nuevo-options";
import videojsOptions from "./config/videojs-options";
import "./Player.css";
import { chromecastInit, chromecastPlugin } from "./plugins/chromecast.plugin";
import { hlsContribEmePlugin, hlsPlugin } from "./plugins/hls.plugin";
import { vastAdsPlugin } from "./plugins/vastAds.plugin";
import { vrPlugin } from "./plugins/vr.plugin";
import {cleanUrlParams, getExtensionFromUrl, getMimeTypeFromExtension} from "../../helpers/urlHelper";
import { dateStringToSecond } from "../../helpers/duration.helper";

export interface INuevoPlayerVideojs extends VideoJsPlayer {
  nuevo: Function;
  playlist: Function;
  vroll: Function;
  loadTracks: Function;
  vr: Function;
  visualizer: Function;
  chromecast: Function;
  vastAds: Function;
  hotkeys: Function;
  chapter_thumbnails: Function;
  qualityLevels: Function;
}

export default class Player extends Component<
  { config: IPlayerConfig | null; isEmbed: boolean; params: string | null,id: string; },
  any
> {
  private player: INuevoPlayerVideojs | undefined;
  private videoNode: any;
  private config: IPlayerConfig;
  private id: string;
  private isEmbed: boolean;
  private params: string;
  private medias: INuevoMedia[] | null = [];

  constructor(props: {
    config: IPlayerConfig;
    isEmbed: boolean;
    params: string;
    id: string;
  }) {
    super(props);
    this.config = { ...props.config };
    this.isEmbed = props.isEmbed;
    this.player = undefined;
    this.id = props.id
    this.params = props.params;
  }

  componentDidMount(): void {
    chromecastInit();

    if (this.config === void 0) {
      return;
    }

    if (this.config.media?.length > 0) {
      if (storageAvailable("localStorage")) {
        localStorage.setItem(
          "volume",
          this.config.player.audio_volume.toString()
        );
        localStorage.setItem(
          "speed",
          this.config.player.show_speed ? this.config.player.default_speed.toString() : '1',
        );
      }

      const hasParams = (source: string): boolean => {
        return [...new URL(source).searchParams].length > 0
      }

      this.medias = this.config?.media.map((media: IMedia) => {
        return {
          id: media.id,
          description: dompurify.sanitize(media.description as string),
          sources: [
            {
              src: `${media.source}${
                this.params !== "" ? `${hasParams(media.source) ? '&' : '?'}${this.params}` : ""
              }`,
              type: getMimeTypeFromExtension(getExtensionFromUrl(cleanUrlParams(media.source))),
            },
          ],
          title: dompurify.sanitize(media.title),
          thumb: media.thumbnails?.image ?? "",
          duration: new Date(Number(media.duration) * 1000)
            .toISOString()
            .slice(11, 19),
          subtitles: media.subtitles || [],
          chapters: {
            url: media.chapters,
            mimetype: getMimeTypeFromExtension(getExtensionFromUrl(media.chapters)),
          },
          timeline: {
            url: media.thumbnails?.timeline ?? '',
            mimetype: getMimeTypeFromExtension(getExtensionFromUrl(cleanUrlParams(media.thumbnails?.timeline))),
          },
        };
      });
    }

    const videoJsOptions: VideoJsPlayerOptions = videojsOptions(
      this.config.player
    );

    if (this.config.media.length === 1 && this.medias !== null) {
      videoJsOptions.sources = this.medias[0].sources;
    }

    this.player = videojs(this.videoNode, videoJsOptions, () => {
      // init videojs in window
      window.videojs = videojs as any;

      if (
        this.config.player.ads !== void 0 &&
        this.config.player.ads.length > 0
      ) {
        this.loadAdsPlugin();
      }

      // CHROMECAST
      chromecastPlugin().onload = () => {
        this.player?.chromecast({
          button: "controlbar",
        });
      };
    }) as INuevoPlayerVideojs;

    (window as any).player = this.player;

    // HLS option
    if (
      videoJsOptions?.sources?.find(
        (source) => source.type === "application/x-mpegurl"
      ) !== void 0
    ) {
      hlsPlugin();
      hlsContribEmePlugin();
    }

    // CONFIG NUEVO OPTIONS
    const nuevoConfig: INuevoOptions = nuevoOptions(
      this.id,
      this.config.player,
      this.config.media
    );

    // init VR / 360 options
    if (this.config.player.is360) {
      vrPlugin();
      nuevoConfig.zoomMenu = false;
    }

    if (this.player !== void 0) {
      this.player.nuevo(nuevoConfig);
      // this.player.visualizer();
    }

    if (this.config.player.lang !== void 0) {
      fetch(`/assets/videojs/lang/${this.config.player.lang}.json`).then(
        (res) => {
          res.json().then((data: videojs.LanguageTranslations) => {
            videojs.addLanguage(this.config.player.lang, data);
          });
        }
      );
    }

    // DISPLAY PLAYLIST

    if (this.config.media.length > 1) {
      this.player?.playlist(this.medias);
    }

    // END DISPLAY PLAYLIST

    if (this.videoNode) {
      this.videoNode.setAttribute("webkit-playsinline", true);
      this.videoNode.setAttribute("playsinline", true)
      this.videoNode.setAttribute("autoplay", true);
    }
  }

  // destroy player on unmount
  componentWillUnmount(): void {
    if (this.player) {
      this.player.dispose();
    }
  }

  // DISPLAY ADS

  loadAdsPlugin(): void {
    vastAdsPlugin().onload = () => {
      if (
        this.config.player.ads !== void 0 &&
        this.config.player.ads.length > 0
      ) {
        this.player?.vastAds(this.configureAds(this.config.player.ads));
      }
    };
  }

  configureAds(ads: IPlayerAds[]): IVastAds[] {
    const adsFormatted: IVastAds[] = ads.map((ad: IPlayerAds) => {
      let temp: IVastAds = {
        tagURL: ad.url,
        id: ad.id,
        hidebar: true,
      };

      if (ad.offset !== void 0 && ad.type !== "pst_roll") {
        const offset = dateStringToSecond(ad.offset)
        temp = {
          ...temp,
          timeOffset: offset,
        };
      }

      if (ad.type === "pst_roll") {
        temp = {
          ...temp,
          timeOffset: "end",
        };
      }

      return temp;
    });

    return adsFormatted;
  }

  // END DISPLAY ADS

  render(): JSX.Element {
    return (
      <div className={`player-container ${this.isEmbed ? "embed" : "share"}`}>
        <div data-vjs-player className="video-player-container">
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-16-9"
            id={`item-${this.id}`}
          />
        </div>
      </div>
    );
  }
}
