import { INuevoPlayerVideojs } from "../components/Player/Player";
import { chaptersThumbnailPlugin } from "../components/Player/plugins/chapters-thumbnails.plugin";
import { INuevoMedia } from "../models/player-config";

export function configureChapter(
  chaptersUrl: string | null,
  player: INuevoPlayerVideojs
): void {
  if (chaptersUrl !== null && chaptersUrl !== undefined && chaptersUrl.length > 0) {
    chaptersThumbnailPlugin().onload = () => {
      player.chapter_thumbnails({
        src: chaptersUrl,
        template: (cue: VTTCue) => {
          return configChaptersTemplate(cue);
        },
      });
    };
  }
}

function configChaptersTemplate(cue: VTTCue): HTMLDivElement {
  let cueText;

  try {
    cueText = JSON.parse(cue.text || "{}");
  } catch (e) {
    cueText = cue.text;
  }

  const { image, title, description } = cueText;

  const template = document.createElement("div");

  template.className = "vjs-chapters-thumbnails-item";

  if (image) {
    const img = document.createElement("img");
    img.className = "vjs-chapters-thumbnails-item-image";
    img.src = image;

    template.appendChild(img);
  }

  if (title) {
    const span = document.createElement("span");
    span.className = "vjs-chapters-thumbnails-item-title";
    span.innerHTML = title;

    template.appendChild(span);
  }
  if (description) {
    const span = document.createElement("span");
    span.className = "vjs-chapters-thumbnails-item-description";
    span.innerHTML = description;

    template.appendChild(span);
  }

  return template;
}
