import React, {  useEffect } from "react";
import { INuevoPlayerVideojs } from "../Player/Player";
import dompurify from "dompurify";
import videojs, { VideoJsPlayerOptions } from "video.js";
import { hlsContribEmePlugin, hlsPlugin } from "../Player/plugins/hls.plugin";
import './Preview.css';
import { registerHlsXHRBeforeRequestEventHandler } from "../../shared/registerHlsXHRBeforeRequestEventHandler";
import {cleanUrlParams, getExtensionFromUrl, getMimeTypeFromExtension} from "../../helpers/urlHelper";
import {
  IMedia,
  IPlayer,
  IPlayerAds,
  IPreviewConfig,
  IPreviewMedia,
  IPreviewPlayerOptions
} from "../../models/player-config";
import {isJsonString} from "../../helpers/json.helper";
import { configureAds, playerPreviewEventHandler } from "../../shared/player-preview-events";
import { configureSubtitles } from "../../shared/subtitles";
import { INuevoOptions } from "../../models/nuevo-options";
import { customizePlayer } from "../../shared/customize";
import { vastAdsPlugin } from "../../components/Player/plugins/vastAds.plugin";


export interface IPreviewProps {
  config: IPreviewConfig | null
}

const isNullish = (value: unknown) => value === null || value === undefined

export default function PreviewPlayer({config}: IPreviewProps) {

  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);


  let player: INuevoPlayerVideojs | undefined;
  let medias: any = [];
  let optionsPlayer: IPreviewPlayerOptions = config?.optionsPlayer ?? {controls: false,  autoplay: true,  startTime: 0, muted: true, };
  let media: IPreviewMedia | null = config?.media ?? null;
  let playerOptions : IPlayer | null = config?.player ?? null;

  const id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );



  useEffect(() => {
    (videojs as any).Vhs.xhr.beforeRequest = registerHlsXHRBeforeRequestEventHandler();

    const addListenersMessage = () => {
      window.addEventListener('message', event => {
        if (event && event.data !== "" && isJsonString(event.data)) {
          if (JSON.parse(event.data)?.player === 'stop') {
            player?.pause();
            player?.dispose();
          } else if (JSON.parse(event.data).media !== void 0 && JSON.parse(event.data).type === 'initPlayerPreview') {
            media = JSON.parse(event.data).media;
            optionsPlayer = JSON.parse(event.data).optionsPlayer;
            playerOptions = JSON.parse(event.data).player;
            buildPlayer();
          }
        }

      })
    }

    if (media) {
      buildPlayer()
    } else {
      window.top.postMessage('player-ready', '*');
      addListenersMessage()
    }


  }, [config])


  const buildPlayer = (nuevoConfig: Partial<INuevoOptions> | null = null): void => {
    if (media !== null) {
      // INIT MEDIAS
      medias = [{
        id: media.mediaId,
        description: dompurify.sanitize(media.description as string),
        sources: [
          {
            src: media.source,
            type: getMimeTypeFromExtension(getExtensionFromUrl(cleanUrlParams(media.source)))
          }
        ],
        title: dompurify.sanitize(media.title ?? ''),
        duration: media.duration,
        chapters: media.chapters,
        subtitles: media.subtitles || [],
        thumb: media.thumbnails.poster ?? "",
        timeline: media.timeline ?? null,
      }]

      let style = '';


      if (optionsPlayer.autoplay) {
        style += `
      .vjs-big-play-button {
        display: none !important;
      }
    `
      }


      if (!optionsPlayer.controls) {
        // define style
        style += `
      .vjs-control-bar {
        display: none !important;
       }
      .vjs-has-started .vjs-control-bar {
        display: none !important;
      }
    `
      }

      const head = document.head || document.getElementsByTagName("head")[0];
      const styleNode = document.createElement("style");
      styleNode.innerHTML = style;
      head.appendChild(styleNode);


      // INIT PLAYER CONFIG
      const videoJsOptions: VideoJsPlayerOptions = {
        autoplay: optionsPlayer.autoplay,
        loop: true,
        controls: optionsPlayer.controls,
        responsive: true,
        preload: 'auto',
        muted: optionsPlayer.muted || optionsPlayer.autoplay,
        fluid: true,
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false,
          nativeCaptions: false,
          vhs: {
            overrideNative: true,
          },
        },
        techOrder: ["chromecast", "html5"],
      }


      if (medias.length === 1) {
        videoJsOptions.sources = medias[0].sources;
      }

      if (!playerRef.current) {
        const videoElement = document.createElement("video-js");
        (videoRef.current as unknown as HTMLElement)?.appendChild(videoElement);

        const playerVideo = (playerRef.current as unknown as INuevoPlayerVideojs) = videojs(videoElement, videoJsOptions, () => {

          if (playerOptions?.ads?.length) {
            vastAdsPlugin().onload = () => {
              playerVideo?.vastAds(configureAds(playerOptions?.ads as IPlayerAds[]));
            };
          }

          if (playerOptions) {
            customizePlayer(playerOptions, 1)
          }

          playerPreviewEventHandler(playerVideo, (config) => onUpdatePlayerLogo(config) )

          if  (!isNullish(media)) {
            configureSubtitles(media as IPreviewMedia, playerVideo as INuevoPlayerVideojs);
          }

          window.videojs = videojs as any;

        }) as unknown as INuevoPlayerVideojs;

        if (!optionsPlayer.autoplay) {
          playerVideo.poster(media.thumbnails.poster)
        }

        if (
          videoJsOptions?.sources?.find(
            (source) => source.type === ("application/x-mpegurl" || "application/vnd.apple.mpegurl")
          ) !== void 0
        ) {
          hlsPlugin();
          hlsContribEmePlugin();
        }

        if (playerVideo !== void 0) {
          playerVideo.nuevo(nuevoConfig ?? {
            video_id: id,
            buttonForward: false,
            rewindButton: false,
            videoInfo: false,
            infoDescription: dompurify.sanitize(media.description as string) ?? "",
            infoTitle: dompurify.sanitize(media.title as string) ?? "",
            resume: false,
            loop: false,
            shareMenu: false,
            contextMenu: false,
            logomin: true,
            logo: playerOptions?.logo_image,
            logoposition: playerOptions?.logo_anchor === 'top_right' ? 'RT': 'LT'
          });

      }

        if (optionsPlayer.startTime) {
          if (secondsToHHMMSS(optionsPlayer.startTime) < media.duration) {
            playerVideo.currentTime(Number(optionsPlayer.startTime));
          }
        }


      } else {
        const player = playerRef.current as unknown as INuevoPlayerVideojs;
        if (videoJsOptions.sources !== void 0) {
          player.src(videoJsOptions.sources);

        }
      }

    }

  }

  const onUpdatePlayerLogo = (config: IPlayer) => {

    playerOptions = config;

    const nuevoConfig: Partial<INuevoOptions> ={
      video_id: id,
      buttonForward: false,
      buttonRewind: false,
      videoInfo: false,
      infoDescription: dompurify.sanitize(media?.description as string) ?? "",
      infoTitle: dompurify.sanitize(media?.title as string) ?? "",
      resume: false,
      loop: false,
      logomin: true,
      shareMenu: false,
      contextMenu: false,
      logo: config.logo_image,
      logoposition: config?.logo_anchor === 'top_right' ? 'RT': 'LT'
    };

    if (playerRef.current) {
      (playerRef?.current as unknown as INuevoPlayerVideojs).dispose();
      playerRef.current = null;
      buildPlayer(nuevoConfig)
    }
  }

  const secondsToHHMMSS = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  // destroy player on unmount
  useEffect(() => {
    return () => {
      if (player && !player?.isDisposed()) {
        player?.dispose();
        playerRef.current = null
      }
    };
  }, [player]);


  return (

    <div data-testid="Preview" className="preview-player-container">
      <div ref={videoRef} id={`item-preview`} className='ik-player-video-js player-preview'/>
    </div>


  // <div data-vjs-player className="video-player-container">
  //     <video
  //       ref={(node) => (this.videoNode = node)}
  //       className="video-js preview-player"
  //       id={`item-${this.media?.mediaId ?? 'preview'}`}
  //     />
  //   </div>

  );

}
