import { INuevoPlayerVideojs } from '../components/Player/Player';
import { customizePlayer } from '../shared/customize';
import { IPlayer, IPlayerAds, IVastAds } from '../models/player-config';
import { vastAdsPlugin } from '../components/Player/plugins/vastAds.plugin';
import { dateStringToSecond } from '../helpers/duration.helper';

export interface IPreviewEventObject {
  play?: boolean;
  pause?: boolean;
  currentTime?: number;
  videoDuration?: string;
  ended?: boolean;
  seeking?: boolean;
  seekingFrom?: number;
  seekedFrom?: number | null;
  seekingTo?: number | null;
  seeked?: boolean;
  seekedTo?: number | null;
  state?: string;
  delta?: number;
  type?: string;
  target?: string;
  currentImage?: string;
}

export function configureAds(ads: IPlayerAds[]): IVastAds[] {
  const adsFormatted: IVastAds[] = ads.map((ad: IPlayerAds) => {
    let temp: IVastAds = {
      tagURL: ad.url,
      id: ad.id,
      hidebar: true,
    };

    if (ad.offset !== void 0 && ad.type !== 'pst_roll') {
      const offset = dateStringToSecond(ad.offset);
      temp = {
        ...temp,
        timeOffset: offset,
      };
    }

    if (ad.type === 'pst_roll') {
      temp = {
        ...temp,
        timeOffset: 'end',
      };
    }

    return temp;
  });

  return adsFormatted;
}

export function playerPreviewEventHandler(
  player: INuevoPlayerVideojs | undefined,
  onUpdatePlayer: (config: IPlayer) => void
): void {

  let seekStart: number | null = null;
  let previousTime = 0;
  let currentTime = 0;



  const communicationPreviewMethod = (obj: IPreviewEventObject) => {
    const body = JSON.parse(
      JSON.stringify(
        Object.assign(
          {
            type: 'player-preview',
          },
          obj
        )
      )
    );

    if(obj.target && obj.target === 'parent') {
      window.parent.postMessage(body, '*');
    } else {
      window.postMessage(body, '*');
    }

  };

  const handleMessagePreview = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data.type === 'extractImagePlayer') {

        const targetRatio = Number(data.ratio.split(':')[0]) / Number(data.ratio.split(':')[1]);
        const width = data.width;
        const height = width / targetRatio;

        player?.pause();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        canvas.style.display = 'none';
        const videoElement = player?.el().firstChild;

        context?.drawImage(
          videoElement as CanvasImageSource,
          0,
          0,
          width,
          height,
        );
        communicationPreviewMethod({
          currentImage: canvas.toDataURL('image/png'),
          target: 'parent'
        });
      }

      if (data.type === 'updatePoster') {
        player?.poster(data.poster)
      }

      if (data.type === 'setCurrentTime') {
        if (data.mode === 'media-edition') {
          player?.currentTime(Number(data.currentTime));
        } else {
          player?.play();
          player?.currentTime(Number(data.currentTime));
          player?.pause();
        }
      }

      if (data.type === 'updatePlayerPreview') {
        onUpdatePlayer(data.player)
      }

      if (data.type === 'changeFormat') {
        player?.aspectRatio(data.format.label);
      }

      if (data.type === 'changePlayerSize') {
        player?.width(data.width);
        player?.height(data.height);
      }

      if (data.type === 'pause') {
        player?.pause();
      }

      if (data.type === 'play') {
        player?.play()?.catch((error) => {
          communicationPreviewMethod({
            state: 'play-error',
            target: 'parent',
          });
        })
      }
  }

  window.addEventListener('message', handleMessagePreview, false);

  player?.on('play', function () {
    communicationPreviewMethod({
      play: true,
      target: 'parent',
    });
  });

  player?.on('pause', function () {
    communicationPreviewMethod({
      play: false,
      target: 'parent',
    });
  });

  player?.on('timeupdate', () => {
    previousTime = currentTime;
    currentTime = player?.currentTime();

    communicationPreviewMethod({
      currentTime: currentTime,
      type: 'timeupdate',
      target: 'parent'
    })

  })

  player?.on('seeking', () => {
    if (seekStart === null) {
      seekStart = previousTime;
    }

    communicationPreviewMethod({
      seekingFrom: previousTime,
      seekingTo: currentTime,
      delta: currentTime - previousTime,
      type: 'seeking',
      target: 'parent'
    })

  });

  player?.on('seeked', () => {
    communicationPreviewMethod({
      seekedFrom: seekStart,
      seekedTo: currentTime,
      delta: currentTime - previousTime,
      type: 'seeked',
      target: 'parent'
    });

    seekStart = null;
  });

}
