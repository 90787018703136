import { IPlaylistConfig } from "../models/player-config";

export function customizePlaylist(playlist: IPlaylistConfig): void {
  let style = '';


  // change theme playlist

  if (playlist.chapter_style === 'light') {
    style += ".vjs-vplaylist { background-color: #f3f3f3 !important;  }";
    style += ".vjs-vplaylist .vjs-head {background-color: #f3f3f3 !important; border-bottom: solid 1px #dbdbdb !important; color: #666 !important }";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item {border-bottom: solid 1px #dbdbdb !important}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item span {color: #666 !important}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item p {color: #666 !important}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item p:hover {color: #666 !important}";
    style += ".vjs-vplaylist .vjs-head .vjs-back i {border: solid #666; border-width: 0 3px 3px 0;}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-active-item {background-color: #dbdbdb !important}";
  }

  if (!playlist.show_media_info) {
    style += ".vjs-vplaylist-show {width: 110px !important}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item p {display: none !important}";
    style += ".vjs-vplaylist .vjs-vlist .vjs-item span {display: none !important}";
  }

  if (!playlist.show_media_thumbnail) {
    style += ".vjs-vplaylist .vjs-vlist .vjs-item .vjs-tmb {display: none !important}";
  }

  if (!playlist.show_media_info && !playlist.show_media_thumbnail || !playlist.show_controls) {
    const buttonPlaylist = document.querySelector('.vjs-playlist-button');
    const playlist = document.querySelector('.vjs-vplaylist' )
    if (buttonPlaylist !== null) {
      buttonPlaylist?.remove()
    }

    if (playlist !== null ) {
      playlist.remove()
    }

  }

  const head = document.head || document.getElementsByTagName("head")[0];
  const styleNode = document.createElement("style");

  styleNode.innerHTML = style;
  head.appendChild(styleNode);
}
