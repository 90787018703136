export function chromecastInit(): HTMLScriptElement {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
  document.body.appendChild(script);

  return script;
}
export function chromecastPlugin(): HTMLScriptElement {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "/assets/videojs/plugins/videojs-chromecast.min.js";
  document.body.appendChild(script);

  return script;
}
