import mime from "mime-types";

export function getMimeTypeFromExtension(extension: string): string {
  return mime.lookup(extension) || 'application/x-mpegurl'
};

export function getExtensionFromUrl(source: string): string {
  return source?.split('.').pop() ?? '';
}

export function cleanUrlParams(url: string) {
  return url?.split('?')[0] ?? '';
}
