import { INuevoPlayerVideojs } from "../components/Player/Player";
import {
  IMedia,
  INuevoMedia,
  IPlayerSubtitle, IPreviewMedia,
  ISubtitle,
} from "../models/player-config";

export function configureSubtitles(
  currentVideo: IMedia | INuevoMedia | IPreviewMedia,
  player: INuevoPlayerVideojs
): void {
  const formattedSubtitle: IPlayerSubtitle[] = [];

  if (
    currentVideo !== void 0 &&
    currentVideo.subtitles !== void 0 &&
    currentVideo.subtitles.length > 0
  ) {
    currentVideo.subtitles.forEach((subtitle: ISubtitle) => {
      formattedSubtitle.push(configSubtitle(subtitle));
    });

    player.loadTracks(formattedSubtitle);
  }
}

export function configSubtitle(subtitle: ISubtitle): IPlayerSubtitle {
  return {
    kind: "captions",
    src: subtitle.source,
    srclang: subtitle.lang,
    label: subtitle.name,
    default: subtitle.default ? "1" : null,
  };
}
