import { Component } from "react";
import videojs, { VideoJsPlayer } from "video.js";
import { storageAvailable } from "../../helpers/common";
import { setDefaultSpeed } from "../../helpers/defaultSpeed";
import { INuevoMedia, IPlayerConfig } from "../../models/player-config";
import { getCurrentVideo, playerReadyFn } from "../../shared/player-ready";
import { SetPlayerTrackerEvents } from "../../shared/player-tracker-event";
import { registerHlsXHRBeforeRequestEventHandler } from "../../shared/registerHlsXHRBeforeRequestEventHandler";
import Player, { INuevoPlayerVideojs } from "../Player/Player";
import * as queryString from "querystring";

export default class Embed extends Component<{ config: IPlayerConfig | null; params: string | null, id: string },
  any> {
  protected config: IPlayerConfig;
  protected params: string;
  protected id: string;
  protected currentVideo: INuevoMedia | null = null;
  protected time: string | null;

  constructor(props: { config: IPlayerConfig; params: string, id: string }) {
    super(props);
    this.config = props.config;
    this.params = props.params;
    this.id = props.id;
    this.time = null;

    this.state = {
      currentVideo: null,
    };
  }

  componentDidMount(): void {

    if (this.config === null) {
      return;
    }

    const player: VideoJsPlayer = videojs(`item-${this.id}`);

    // player loading failed
    if (player === void 0) {
      return;
    }

    // SET IK REFERRER FOR TOKEN
    (videojs as any).Vhs.xhr.beforeRequest =
      registerHlsXHRBeforeRequestEventHandler();

    this.configurePlayer(player);
    this.registerReadyHandler(player);
    this.registerFirstPlayHandler(player);
    this.registerVolumeChangedHandler(player);
  }

  configurePlayer(player: VideoJsPlayer): void {
    player.playbackRate(this.config.player.default_speed);
  }

  registerReadyHandler(player: VideoJsPlayer): void {
    player.ready(() => {
      SetPlayerTrackerEvents(player as INuevoPlayerVideojs, this.config)
      playerReadyFn(player as INuevoPlayerVideojs, this.config);
    });
  }

  registerFirstPlayHandler(player: VideoJsPlayer): void {
    player.on("firstplay", () => {

      if (this.config.media.length > 1) {
        // We override the type to acces to nuevo
        this.setState(() => ({
          currentVideo: getCurrentVideo("playlist", player, this.config),
        }));
      } else {
        this.setState(() => ({
          currentVideo: getCurrentVideo("single", player, this.config),
        }));
      }

      const params = new URLSearchParams(document.location.search);
      this.time = params.get('t');

      if (this.time !== void 0 && this.time !== null && this.state.currentVideo?.duration !== null && this.time < this.state.currentVideo?.duration) {
        player.currentTime(Number(this.time))
      }


      setTimeout(() => {
        if (
          storageAvailable("localStorage") &&
          localStorage.getItem("speed") !== void 0
        ) {
          player.playbackRate(Number(localStorage.getItem("speed")));
          setDefaultSpeed(this.config.player.show_speed ? Number(localStorage.getItem("speed")) : 1);
        } else {
          player.playbackRate(this.config.player.show_speed ? this.config.player.default_speed : 1);
          setDefaultSpeed(this.config.player.show_speed ? this.config.player.default_speed : 1);
        }

        if (
          storageAvailable("localStorage") &&
          localStorage.getItem("volume") !== void 0
        ) {
          player.volume(Number(localStorage.getItem("volume")));
        } else {
          player.volume(this.config.player.audio_volume);
        }
      }, 300);
    });
  }

  registerVolumeChangedHandler(player: VideoJsPlayer): void {
    if (storageAvailable("localStorage")) {
      player.on("volumechange", () => {
        if (localStorage.getItem("volume")) {
          localStorage.setItem("volume", (player.volume() * 100).toString());
        }
      });
    }
  }

  render(): JSX.Element {
    return (
      <div className="embed-container">
        <div className="embed-container--player">
          <Player config={this.config} isEmbed={true} params={this.params} id={this.id}/>
        </div>
      </div>
    );
  }
}
