import { VideoJsPlayerOptions } from "video.js";
import { IPlayer } from "../../../models/player-config";

export default function videojsOptions(player: IPlayer | null = null): VideoJsPlayerOptions {
  return {
    autoplay: player?.auto_start ?? false,
    loop: false, // set to false because there is no config actually in the manager to parameters this
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
    width: player?.width || 720,
    height: player?.height || 320,
    controls: player?.show_controls ?? false,
    responsive: true,
    preload: 'none',
    controlBar: {
      playToggle: true,
    },
    muted: player?.auto_start ?? false,
    fluid: true,
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: false,
      nativeCaptions: false,
      vhs: {
        overrideNative: true,
      },
    },
    techOrder: ["chromecast", "html5"],
  };
}
