import { INuevoPlayerVideojs } from "../components/Player/Player";
import { IMedia, INuevoMedia, IPlayer, IPlayerConfig } from "../models/player-config";
import { configureChapter } from "./chapters";
import { customizePlayer } from "./customize";
import { playerEventHandler } from "./player-events";
import { configureSubtitles } from "./subtitles";
import { customizePlaylist } from "./customize-playlist";
import dompurify from "dompurify";
import { vrPlugin } from "../components/Player/plugins/vr.plugin";
import videojs from "video.js";

export function playerReadyFn(
  player: INuevoPlayerVideojs,
  config: IPlayerConfig
): void {
  playerEventHandler(config, player);

  let retries = 0;
  const retries_limit = 2;

  player.on('play', () => {
    // update poster when playlist
    if (config.media.length > 1) {
      const currentVideo: INuevoMedia | IMedia = getCurrentVideo('playlist', player, config);

      // load chapters for media in playlist
      if (currentVideo.chapters !== void 0) {
        configureChapter((currentVideo as INuevoMedia).chapters.url, player);
	  }

      // load subtitle for media in playslit
      if (currentVideo.subtitles !== void 0) {
        configureSubtitles(currentVideo as any, player);
      }

      initPosterPlaylist(player, config, currentVideo)
    }
  })

  // it seems that the vr plugin is sometimes badly initialized, if undefined at play time restarts the vr plugin.
  player.on('firstplay', () => {
    if (config.player.is360) {
      vrPlugin();
      initVr(config, player)
    }
  });

  player.on("error", (e) => {
    player.loadingSpinner.dispose();
    e.stopImmediatePropagation();
    const error = player.error();
    if (error?.code === 4) {
      if (config.player.interrupt_image !== void 0) {
        player.poster(config.player.interrupt_image);
      } else {
        player.poster("/assets/images/player/player-interrupted.png");
      }
    }
  });

  player.tech(true).on("retryplaylist", () => {
    retries++;
    if (retries === retries_limit) {
      player.loadingSpinner.dispose();
      if (config.player.interrupt_image !== void 0) {
        player.poster(config.player.interrupt_image);
      } else {
        player.poster("/assets/images/player/player-interrupted.png");
      }
    }
  });

  // VR INIT
  initVr(config, player);


  // LOOP INIT
  player.loop(config.player.show_loop);

  // INIT HOTKEY
  player.hotkeys({
    volumeStep: 0.1,
    seekStep: 5,
    alwaysCaptureHotkeys: true,
  });

  // APPLY PLAYER STYLE
  customizePlayer(config.player, config.media.length);

  if (config.playlist !== void 0) {
    customizePlaylist(config.playlist);
  }

  // PLAYLIST
  if (config.media.length > 1) {
    initPlaylist(player, config);
  } else {
    // SINGLE MEDIA
    initSingleMedia(player, config);
  }
}

function initVr(config: IPlayerConfig, player: INuevoPlayerVideojs) : void {

  let currentMedia = null;

  if (config.media.length > 1) {
    currentMedia = getCurrentVideo('playlist', player, config);
  } else {
    currentMedia = getCurrentVideo('single', player, config);
  }

  if(player.vr !== undefined) {
    const media_vr_type = currentMedia?.side_data?.find(value => value.type)?.type;

    if (media_vr_type !== void 0) {

      if (media_vr_type === '2D') {
        player.vr({
          projection: 'equirectangular',
          motionControls: true,
        });
      } else if (media_vr_type === 'top and bottom') {
        player.vr({
          projection: '360_TB',
          motionControls: true,
        });
      } else {
        player.vr({
          projection: '360_TB',
          motionControls: true,
        });
      }
    }
  }
}


function initPlaylist(player: any, config: IPlayerConfig): void {
  const currentVideo = getCurrentVideo(
    "playlist",
    player,
    config
  ) as INuevoMedia;

  initPosterPlaylist(player, config, currentVideo)

  configureChapter((currentVideo as INuevoMedia).chapters.url, player);

  if (currentVideo.subtitles !== void 0) {
    configureSubtitles(currentVideo as any, player);
  }
}

function initPosterPlaylist(player: any, config: IPlayerConfig, currentVideo: INuevoMedia | IMedia): void {
  const currentMedia: IMedia | undefined = config.media.find((m) => m.id === currentVideo.id);
  const currentMediaIndex = (player as any).playlist.currentIndex();

  if (currentMediaIndex === 0 && currentMedia) {

    if (config.playlist.image !== void 0) {
      player.poster(config.playlist.image)
    } else {
      player.poster(player?.thumbnails?.poster)
    }

  } else {
    player.poster('');
  }
}

function initSingleMedia(player: any, config: IPlayerConfig): void {
  if (config?.media.length > 0 && config?.media[0].thumbnails?.poster !== void 0 && config.player?.preload_image !== void 0 ) {
    player.poster(config.player?.preload_image );
  } else {
    // INIT PRELOAD IMAGES
    if (config.player?.preload_image !== void 0) {
      player.poster(config.player?.preload_image ?? '');
    } else {
      player.poster(config.media[0]?.thumbnails?.poster ?? '');
    }
  }

  const currentVideo = getCurrentVideo("single", player, config);

  configureChapter((currentVideo as IMedia).chapters, player);

  if (config?.media.length > 0 && config.media[0].subtitles !== void 0) {
    configureSubtitles(currentVideo, player);
  }
}

export function getCurrentVideo(
  type: "playlist" | "single",
  player: any,
  config: IPlayerConfig
): INuevoMedia | IMedia {
  let playlist;

  if (type === "playlist") {
    playlist = player.playlist() || null;
  }

  let currentVideo: INuevoMedia | IMedia;

  switch (type) {
    case "playlist":
      currentVideo = playlist[player.playlist.currentIndex()];
      break;
    case "single":
      currentVideo = config?.media[0];
      break;
    default:
      currentVideo = config?.media[0];
  }

  return currentVideo;
}
