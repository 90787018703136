import { Component } from "react";

export interface IError {
  errcode: number;
  errstr: string;
}

export default class Error extends Component<{ error: IError }, any> {
  error: IError;

  constructor(props: { error: IError }) {
    super(props);
    this.error = props.error;
  }

  render(): JSX.Element {
    if (this.error.errcode === 404) {
      return (
        <div>
          <h1 style={{ textAlign: "center" }}>
            Nous n'avons pas trouve de lien de partage correspondant a cet id
          </h1>
        </div>
      );
    } else {
      return (
        <div>
          <h1 style={{ textAlign: "center" }}>
            {this.error.errcode} {this.error.errstr}
          </h1>
        </div>
      );
    }
  }
}
