import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Embed from "./components/Embed/Embed";
import Error from "./components/Error/Error";
import Share from "./components/Share/Share";
import Password from "./components/Password/Password";
import { getConfigV3Async } from "./helpers/configHelper";
import {IPlayerConfig, IPreviewConfig} from "./models/player-config";
import dompurify from "dompurify";
import PreviewPlayer from "./components/Preview/Preview";
import { Error401Interceptor } from "./interceptors/error-401-interceptors";

export interface IParams {
  token?: string;
}

class App extends Component<any, { config: IPlayerConfig | null; error: any; protected: boolean; previewAvailable: boolean; previewConfig: IPreviewConfig | null}> {
  public params: any;
  public paramsStr: string;
  public id: string;

  constructor(props: IPlayerConfig) {
    super(props);
    this.state = {
      config: null,
      error: null,
      protected: false,
      previewAvailable: !window.location.search.includes('protected'),
      previewConfig: null,
    };

    this.params = {};
    this.paramsStr = "";
    this.id = '';
  }

  updateConfig = (newConfig: IPlayerConfig | IPreviewConfig | null, isPreview = false) => {
    if (isPreview) {
      this.setState({
        previewConfig: newConfig as IPreviewConfig,
        previewAvailable: true,
      });
    } else {
      this.setState({
        config: newConfig as IPlayerConfig,
        error: null,
        protected: false,
      });
    }
  }

  componentDidMount(): void {

    const urlParams = new URLSearchParams(window.location.search);
    const preview = urlParams.get("preview");

    if (!preview) {
      this.GetConfigPlayer();
    }
  }

  GetConfigPlayer(): void {
    this.id = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );

    this.params = {};

    const urlParams = new URLSearchParams(window.location.search);

    this.params = Object.fromEntries([...urlParams.entries()]);

    this.paramsStr = "";

    this.paramsStr = Object.keys(this.params)
      .map((key: string) => {
        return "" + key + "=" + this.params[key];
      })
      .join("&");

    getConfigV3Async((window as any).runtime.apiV3Url, this.id)
      .then((res: IPlayerConfig) => {

        if (res !== void 0) {

          res = {
            ...res,
            media: res.media.map((m) => {
              return {
                ...m,
                description: dompurify.sanitize(m.description as string),
                title: dompurify.sanitize(m.title)
              }
            }),
          }


          this.setState(() => ({ config: res }));

        }


      })
      .catch((err) => {
        this.setState(() => ({
          error: { errcode: err.status, errstr: err.data?.error?.description ?? '' },
        }));

        if (err.status === 401) {
          this.setState({protected: true});
        }
      });
  }

  render(): JSX.Element {
    const PlayerEmbedRoute = () => {
      if (this.state.config === null && this.state.error === null) {
        return <React.Fragment />;
      }

      if (this.state.protected) {
        return <Password config={this.state.config} params={this.paramsStr} updateConfig={this.updateConfig} page={'embed'} id={this.id} />
      }

      if (this.state.error !== null) {
        //TODO: capture api error code/message and forward
        return <Error error={this.state.error} />;
      }
      return <Embed id={this.id} config={this.state.config} params={this.paramsStr} />;
    };

    const PlayerShareRoute = () => {
      if (this.state.config === null && this.state.error === null) {
        return <React.Fragment />;
      }

      if (this.state.protected) {
        return <Password config={this.state.config} params={this.paramsStr} updateConfig={this.updateConfig} page={'share'} id={this.id} />
      }

      if (this.state.error !== null) {
        //TODO: capture api error code/message and forward
        return <Error error={this.state.error} />;
      }

      return <Share config={this.state.config} id={this.id} params={this.paramsStr} />;
    };

    const PlayerPreviewRoute = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const isMediaProtected = urlParams.get("protected");

      // we must check for 'protected' queryParams in case the preview is protected
      // we can't check if the media is protected in the share config as with /embed or /share because the preview has no share config
      if (isMediaProtected && !this.state.previewAvailable) {
        return <Password config={this.state.config} params={window.location.search} updateConfig={this.updateConfig} page={'media'} id={this.id} />
      } else {
        return <PreviewPlayer config={this.state.previewConfig}/>
      }
    }

    const NoMatch = () => {
      return (
        <div>
          <h1 style={{ textAlign: "center" }}>404 Not Found</h1>
          <hr />
          <p style={{ textAlign: "center" }}>react</p>
        </div>
      );
    };

    return (
      <Router>
        < Error401Interceptor />
        <Routes>
          <Route path="/embed/:id" element={<PlayerEmbedRoute />}></Route>
          <Route path="/share/:id" element={<PlayerShareRoute />}></Route>
          <Route path="/media/:id" element={<PlayerPreviewRoute />}></Route>
          <Route path="*" element={<NoMatch />}></Route>
        </Routes>
      </Router>
    );
  }
}

export default App;
