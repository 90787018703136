import {IPlayerConfig, IPreviewConfig} from "../../models/player-config";
import React, {Component} from "react";
import "./Password.css"
import { getConfigV3Async } from "../../helpers/configHelper";
import dompurify from "dompurify";
import { useNavigate } from 'react-router-dom';
import {isJsonString} from "../../helpers/json.helper";

export interface PasswordProps {
  navigate: ReturnType<typeof useNavigate>;
  config: IPlayerConfig | null;
  params: string;
  page: 'embed' | 'share' | 'media';
  id: string;
  updateConfig: (config: IPlayerConfig | IPreviewConfig | null, isPreview: boolean) => void;
}

class Password extends Component<PasswordProps, any> {

  protected config: IPlayerConfig | null;
  protected params: string;
  protected page: 'embed' | 'share' | 'media';
  protected id: string;
  protected lang: 'fr' | 'en' | 'es' | 'de' | 'it' = 'en';
  protected isPreview: boolean;
  private previewConfig: IPreviewConfig | null;


  constructor(props: PasswordProps) {
    super(props);
    this.config = props.config;
    this.params = props.params;
    this.page = props.page;
    this.id = props.id;
    this.lang = this.displayLang(this.config?.player?.lang ?? navigator.language?.split('-')[0]);
    this.isPreview = this.params.includes('preview') && this.params.includes('protected');
    this.previewConfig = null;

    this.state = {
      password: '',
      invalidPassword: false,
    }
    this.handleConfigUpdate.bind(this);
  }

  componentDidMount(): void {
    const passwordInput = document.getElementById('password-input');
    passwordInput?.addEventListener('keydown', this.handleEnterKeyPressed);

    if (this.isPreview) {
      window.top.postMessage('player-ready', '*');
      window.addEventListener('message', this.handlePreviewConfigEvent);
    }
  }

  componentWillUnmount(): void {
    const passwordInput = document.getElementById('password-input');
    passwordInput?.removeEventListener('keydown', this.handleEnterKeyPressed);

    if (this.isPreview) {
      window.removeEventListener('message', this.handlePreviewConfigEvent);
    }
  }

  displayLang(lang: string): 'fr' | 'en' | 'es' | 'de' | 'it' {
    if (
      lang === 'en' ||
      lang === 'fr' ||
      lang === 'es' ||
      lang === 'it' ||
      lang === 'de'
    ) {
      return lang;
    } else {
      return 'en';
    }
  }

  translatedInvalidPassword(lang: 'fr' | 'en' | 'es' | 'de' | 'it'): string {
    switch (lang) {
      case 'fr':
        return 'Le mot de passe est invalide';
      case 'en':
        return 'The password is invalid';
      case 'de':
        return 'Das Passwort ist ungültig';
      case 'es':
        return 'La contraseña no es válida';
      case 'it':
        return 'La password non è valida';
      default:
        return '';
    }
  }

  translatedHeader(lang: 'fr' | 'en' | 'es' | 'de' | 'it'): string {
    switch (lang) {
      case 'fr':
        return 'Ce média est protégé';
      case 'en':
        return 'This media is protected';
      case 'de':
        return 'Dieses Medien ist geschützt';
      case 'es':
        return 'Este media está protegida';
      case 'it':
        return 'Questo media è protetto';
      default:
        return '';
    }
  }

  translatedLabel(lang: 'fr' | 'en' | 'es' | 'de' | 'it'): string {
    switch (lang) {
      case 'fr':
        return 'Pour y accéder, veuillez saisir le mot de passe';
      case 'en':
        return 'To access it, please enter the password';
      case 'de':
        return 'Um darauf zuzugreifen, geben Sie bitte das Passwort ein';
      case 'es':
        return 'para verlo, por favor, introduzca la contraseña';
      case 'it':
        return 'Per visualizzarlo, si prega di immettere la password';
      default:
        return '';
    }
  }

  translatedButtonText(lang: 'fr' | 'en' | 'es' | 'de' | 'it'): string {
    switch (lang) {
      case 'fr':
        return 'Accéder au média';
      case 'en':
        return 'Go to media';
      case 'de':
        return 'Zu den Medien';
      case 'es':
        return 'Acceder a la media';
      case 'it':
        return 'Accesso ai media';
      default:
        return '';
    }
  }

  handlePreviewConfigEvent = (event: MessageEvent): void => {
    if (event && event.data !== "" && isJsonString(event.data)) {
      this.previewConfig = JSON.parse(event.data);
    }
  }

  handleConfigUpdate = (config: IPlayerConfig | IPreviewConfig | null, isPreview = false) => {
    this.props.updateConfig(config, isPreview);
  }

  handleEnterKeyPressed = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.checkPassword();
    }
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password: event.target.value});
    this.setState({invalidPassword: false});
  };

  checkPassword = () => {
    if (this.isPreview) {
      if (this.state?.password === this.previewConfig?.media.password) {
        this.handleConfigUpdate(this.previewConfig, true);
        this.props.navigate(`/${this.page}/${this.previewConfig?.media.mediaId}?preview=1`);
      } else {
        this.setState({invalidPassword: true});
      }
    } else {
      getConfigV3Async((window as any).runtime.apiV3Url, this.id, this.state?.password)
        .then((res: IPlayerConfig) => {
          if (res !== void 0) {
            res = {
              ...res,
              media: res.media.map((m) => {
                return {
                  ...m,
                  description: dompurify.sanitize(m.description as string),
                  title: dompurify.sanitize(m.title)
                }
              }),
            }

            this.handleConfigUpdate(res);
            this.props.navigate(`/${this.page}/${this.id}`);
          }
        })
        .catch((err) => {
          this.setState({invalidPassword: true});
        });
    }
  };

  render(): JSX.Element {
    return (
      <div className="password-container">
        <div className="password-wrapper">
          <div className="lock-icon">
            <img className="lock-icon-img" src="/assets/images/lock.svg" alt="lock" />
          </div>
          <span className="password-header">{this.translatedHeader(this.lang)}</span>
          <span className="password-label">{this.translatedLabel(this.lang)}</span>
          <div className="password-input-wrapper">
            <input autoFocus id="password-input" autoComplete="off" onChange={this.handleInputChange}></input>
            <button disabled={this.state?.password?.length === 0} className="check-password" onClick={this.checkPassword}>{this.translatedButtonText(this.lang)}</button>
          </div>
          {this.state?.invalidPassword &&
            <span className="invalid-password">{this.translatedInvalidPassword(this.lang)}</span>
          }
        </div>
      </div>
    );
  }
}

const withRouterHOC = <Props extends { navigate: ReturnType<typeof useNavigate> }>(
  Component: React.ComponentType<Props>
) => {
  return (props: Omit<Props, keyof { navigate: ReturnType<typeof useNavigate> }>) => {
    const navigate = useNavigate();

    return (
      <Component
        {...(props as Props)}
        navigate={navigate}
      />
    );
  };
};

export default withRouterHOC(Password);
