export interface IOPtionXhr {
  callback?: Function;
  crossDomain: boolean;
  headers?: {
    "X-Ik-Referer"?: string;
    "X-Cookie"?: string;
  };
  timeout?: number;
  uri?: string;
  withCredentials?: boolean;
}

export function registerHlsXHRBeforeRequestEventHandler(): Function {
  const referer =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  const getCookie = (name: string): string => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() ?? ""
    }
    return "";
  }

  return (opt: IOPtionXhr) => {
    if (opt.headers === void 0) {
      opt.headers = {};
    }

    opt.headers["X-Ik-Referer"] = referer;
    opt.crossDomain = true;

    // in order to be able to whitelist ACLs for cals originated
    // from the manager, we need to pass cookies.
    // xhr.withCredentials won't work since we use 'Access-Control-Allow-Origin: *'
    // so we need to provide manually the only public cookie we can
    //
    // this is not secure at all since anyone defining a MANAGER-XSRF-TOKEN cookie
    // can bypass ACL verification, but until we do a more strict IP address
    // verification this is the only way to achieve this
    // TODO: remove this once IP verification is enforced in nginx/proxy chain
    const xsrfToken: string = getCookie("MANAGER-XSRF-TOKEN");
    if (xsrfToken !== "") {
      opt.headers["X-Cookie"] = "MANAGER-XSRF-TOKEN=" + xsrfToken;
    }

    return opt;
  };
}
