export function dateStringToSecond(time: string): string {
  const parts: string[] = time.split(':');
  const hours: number = parseInt(parts[0], 10);
  const minutes: number = parseInt(parts[1], 10);
  const secondsAndMilliseconds: string[] = parts[2].split('.');
  const seconds: number = parseInt(secondsAndMilliseconds[0], 10);
  const milliseconds: number = parseInt(secondsAndMilliseconds[1], 10);

  return String((hours * 3600) + (minutes * 60) + seconds + (milliseconds / 100));

}
