import { INuevoOptions } from "../../../models/nuevo-options";
import { IMedia, IPlayer } from "../../../models/player-config";
import dompurify from "dompurify";

export default function nuevoOptions(
  shareId: string,
  player: IPlayer,
  media: IMedia[]
): INuevoOptions {
  let opts = {
    video_id: `item-${shareId}`,
    buttonForward: true,
    rewindButton: true,
    contextMenu: false,
    videoInfo: true,
    infoTitle: media.length > 0 ? media[0]?.title : "",
    infoDescription:
      media.length > 0 && media[0]?.description ? media[0].description.length > 230  ? dompurify.sanitize(media[0].description.substring(0, 230) + "...") : dompurify.sanitize(media[0].description) : "",
    resume: true,
    playlistID: "playlist-container",
    playlistMaxH: "auto",
    zoomMenu: true,
    chapterMarkers: true,
    loop: media.length !== 0 && player.show_loop,
    shareMenu:
      player.enable_embed_code ||
      (player.enable_facebook && player.facebook_back_link !== "") ||
      (player.enable_twitter && player.twitter_back_link !== ""),
    embed: player.enable_embed_code
      ? `<iframe frameborder="0" width="${player.width}" height="${player.height}"  src="player.vod2.infomaniak.com/embed/${shareId}" allowfullscreen></iframe>`
      : "",
    url: player.facebook_back_link ?? null,
    twitter_url: player.twitter_back_link ?? null,
    twitter_related: player.twitter_related ?? null,
    twitter_text: player.twitter_via ?? null
  };

  if (
    player.show_media_thumbnail &&
    media.length > 0 &&
    media[0].thumbnails?.timeline !== void 0
  ) {
    opts = {...opts, ...defineThumbnail(media)};
  }

  if (player.logo_image) {
    opts = {...opts, ...defineLogo(player)};
  }

  if (player.show_default_logo) {
    opts = {...opts, ...defineDefaultLogo()};
  }

  return opts;
}

export function defineThumbnail(media: IMedia[]): INuevoOptions {
  return {
    slideImage: media[0].thumbnails?.timeline ?? "",
    slideType: "horizontal",
    slideWidth: 140,
    slideHeight: 100,
  };
}

function defineLogo(player: IPlayer): INuevoOptions {
  const logoOpts = {
    logomin: true,
    logo: player.logo_image,
  } as INuevoOptions;

  switch (player.logo_anchor) {
    case "top_right":
      logoOpts.logoposition = "RT";
      break;
    case "top_left":
      logoOpts.logoposition = "LT";
      break;
  }

  return logoOpts;
}

function defineDefaultLogo(): INuevoOptions {
  return {logocontrolbar: "/assets/images/byIk.png"} as INuevoOptions;
}
