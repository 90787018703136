export function hlsPlugin(): HTMLScriptElement {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "/assets/videojs/plugins/hlsjs.js";
  document.body.appendChild(script);

  return script;
}

export function hlsContribEmePlugin(): HTMLScriptElement {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "/assets/videojs/plugins/videojs-contrib-eme.js";
  document.body.appendChild(script);

  return script;
}
