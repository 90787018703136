import { IMedia, IPlayerConfig, IPlaylistConfig } from "../../models/player-config";
import Embed from "../Embed/Embed";
import Player from "../Player/Player";
import "./Share.css";
import dompurify from "dompurify";

export interface IOPtionXhr {
  callback: Function;
  headers: {
    "X-Ik-Referer"?: string;
  };
  timeout: number;
  uri: string;
  withCredentials: boolean;
}

export default class Share extends Embed {
  public linkToIk = (lang: string) =>
    `${(window as any).runtime.infomaniakUrl}/${this.displayLang(
      lang
    )}/multimedia/vod-aod`;

  public linkToSupport = (lang: string) =>
    `${(window as any).runtime.infomaniakUrl}/${this.displayLang(
      lang
    )}/support/contact`;

  constructor(props: { config: IPlayerConfig; params: string, id: string; }) {
    super(props);
    this.config = props.config;
    this.params = props.params;
    this.id = props.id;

    this.state = {
      currentVideo: null,
    };
  }

  displayLang(lang: string): string {
    if (
      lang === "en" ||
      lang === "fr" ||
      lang === "es" ||
      lang === "it" ||
      lang === "de"
    ) {
      return lang;
    } else {
      return "en";
    }
  }

  convertTime(duration: number): string {
    let ret = "";

    if (duration !== void 0) {
      // Hours, minutes and seconds
      const hrs = ~~(duration / 3600);
      const mins = ~~((duration % 3600) / 60);
      const secs = ~~duration % 60;

      if (hrs === 0) {
        ret += "00";
      } else if (hrs < 10 && hrs !== 0) {
        ret += "0" + hrs;
      } else {
        ret += hrs;
      }

      ret += " : ";

      if (mins === 0) {
        ret += "00";
      } else if (mins < 10 && mins !== 0) {
        ret += "0" + mins;
      } else {
        ret += mins;
      }

      ret += " : ";

      if (secs === 0) {
        ret += "00";
      } else if (secs < 10 && secs !== 0) {
        ret += "0" + secs;
      } else {
        ret += secs;
      }

      return ret;
    } else {
      return ret;
    }
  }

  Description(props: string): JSX.Element | undefined {
    if (props !== void 0 && props.length > 0) {
      return (
        <div className="share-container--infos__description">
          <h4> Description </h4>
          <p> {props} </p>
        </div>
      );
    } else {
      return void 0;
    }
  }

  PlaylistContainer(media: IMedia[]) {
    if (media.length > 1 && this.config.playlist !== void 0 && this.config.playlist.nav_position === 'below') {
      return <div id="playlist-container"></div>;
    }
  }

  PlaylistDescription(playlist: IPlaylistConfig): JSX.Element | undefined {
    if (this.config.media.length > 1 && playlist !== void 0) {
      return (
        <div className="share-container--playlist-description">
          <h4>{dompurify.sanitize(playlist.name)}</h4>
          <p>{dompurify.sanitize(playlist.description as string)}</p>
        </div>
      );
    } else {
      return void 0;
    }
  }

  VodContactLabel(lang: string): JSX.Element {
    switch (lang) {
      case "fr":
        return (
          <div className="footer-label">
            <span> Média partagé à l'aide </span>
            <a href={this.linkToIk(lang)} className="link">
              d'Infomaniak VOD/AOD.{" "}
            </a>
            <span>En cas de question, n'hésitez pas à </span>
            <a href={this.linkToSupport(lang)}>nous contacter.</a>
          </div>
        );

      case "en":
        return (
          <div className="footer-label">
            <span>Media shared with the help of </span>
            <a href={this.linkToIk(lang)} className="link">
              Infomaniak VOD/AOD.{" "}
            </a>
            <span>In case of questions please </span>
            <a href={this.linkToSupport(lang)}>contact us</a>
          </div>
        );

      case "es":
        return (
          <div className="footer-label">
            <span>Medios compartidos con la ayuda de</span>
            <a href={this.linkToIk(lang)} className="link">
              Infomaniak VOD/AOD.{" "}
            </a>
            <span>Si tiene alguna pregunta, no dude en ponerse en</span>
            <a href={this.linkToSupport(lang)}>contacto con nosotros</a>
          </div>
        );

      case "de":
        return (
          <div className="footer-label">
            <span>Geteilte Medien mit Hilfe von</span>
            <a href={this.linkToIk(lang)} className="link">
              Infomaniak VOD/AOD.{" "}
            </a>
            <span>Bei Fragen zögern Sie nicht </span>
            <a href={this.linkToSupport(lang)}>uns zu kontaktieren.</a>
          </div>
        );

      case "it":
        return (
          <div className="footer-label">
            <span>Media condivisi con l'aiuto di</span>
            <a href={this.linkToIk(lang)} className="link">
              Infomaniak VOD/AOD.
            </a>
            <span>Se avete domande, non esitate</span>
            <a href={this.linkToSupport(lang)}>a contattarci</a>
          </div>
        );

      default:
        return (
          <div className="footer-label">
            <span>Media shared with the help of </span>
            <a href={this.linkToIk(lang)} className="link">
              Infomaniak VOD/AOD.{" "}
            </a>
            <span>In case of questions please </span>
            <a href={this.linkToSupport(lang)}>contact us</a>
          </div>
        );
    }
  }

  render(): JSX.Element {

    let duration;
    let title

      if ((this.config.playlist !== void 0 && this.config.playlist.mode === 'split') || this.config.media.length === 1) {
        duration = <p>{this.convertTime(this.state.currentVideo?.duration)}</p>
        title = <p>{this.state.currentVideo?.title}</p>
      }

    return (
      <div className="share-container">
        <div className="share-container--logo">
          <img src="/assets/images/logo-infomaniak-blue.svg" alt="logo infomaniak" />
        </div>

        <div className="share-container--player">
          {this.PlaylistDescription(this.config.playlist)}

          <Player config={this.config} isEmbed={false} params={this.params} id={this.id} />

           {this.PlaylistContainer(this.config.media)}

          <div className="share-container--infos">
            <div className="share-container--infos__title-duration">
              {title}
              {duration}
            </div>

            {this.Description(this.state.currentVideo?.description)}
          </div>
        </div>

        {this.VodContactLabel(this.config.player.lang)}
      </div>
    );
  }
}
