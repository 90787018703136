import $ from "jquery";
import { IPlayerConfig, IResponse } from "../models/player-config";

export const getConfigV3Async = (
  urlConfig: string,
  id: string | null,
  password?: string,
): Promise<IPlayerConfig> => {
  let headers: any;
  if (password !== void 0) {
    headers = {
      'WWW-Authenticate': password,
    }
  }

  return new Promise((resolve, reject) => {
    $.ajax({
      type: "GET",
      crossDomain: true,
      async: true,
      headers: headers,
      url: `${urlConfig}/shares/${id}.json?with=subtitles`,
      success: (result: IResponse) => {
        resolve(result.data);
      },
      error: (result) => {
        reject({ status: result.status, data: result.responseJSON });
      },
    });
  });
};
