import { VideoJsPlayer } from "video.js";
import { INuevoPlayerVideojs } from "../components/Player/Player";
import { IPlayerConfig } from "../models/player-config";
import { getCurrentVideo } from "./player-ready";
import { customizePlaylist } from "../shared/customize-playlist";

export interface ICommunicationMethodObject {
  play?: boolean;
  player?: VideoJsPlayer;
  currentTimeFn?: Function;
  currentTime?: number;
  paused?: boolean;
  pause?: boolean;
  chapters?: TextTrack;
  currentVideoId?: string;
  currentImage?: string;
  target?: string;
}

export function playerEventHandler(
  config: IPlayerConfig,
  player: INuevoPlayerVideojs
): void {
  const communicationMethod = (obj: ICommunicationMethodObject) => {
    const body = JSON.parse(
      JSON.stringify(
        Object.assign(
          {
            type: "player",
          },
          obj
        )
      )
    );

    if(obj.target && obj.target === 'parent') {
      window.parent.postMessage(body, '*')
    } else {
      window.postMessage(body, "*");
    }

  };

  const handleMessage = function (event: MessageEvent) {
    if (event.data && event.data.name === "managerupdate") {
      // updateWithManagerDatas(event.data.object, configuration);
      return;
    }

    if (event.data && event.data.name === "updateStylePlaylist") {
      if (event.data.object.playlistConfig !== void 0) {
        customizePlaylist(event.data.object.playlistConfig)
      }
    }

    if (event.data && event.data.name === "managerupdateposter") {
      (document.getElementsByClassName("vjs-tech")[0] as any).poster = "";
      (
        document.getElementsByClassName("vjs-poster")[0] as any
      ).style.backgroundImage = "url(" + event.data.url + ")";

      return;
    }

    if (event.data && event.data.name === "managerupdatedefaultsubtitle") {
      // configureSubtitles(configuration.target);
    }

    if (event.data && event.data.type === "timeline") {
      if (event.data.details === "chapter-added") {
        setTimeout(function () {
          // configureChapters(configuration.player, true, true);
        }, 400);
      }
    }

    if (event.data === "getPlayer") {
      player.on("timeupdate", function () {
        communicationMethod({
          currentTime: player.currentTime(),
          paused: player.paused(),
        });
      });

      player.on("play", function () {
        communicationMethod({
          play: true,
        });
      });
      player.on("pause", function () {
        communicationMethod({
          pause: true,
          currentTime: player.currentTime(),
        });
      });
      player.on("loadedmetadata", function () {


        const tracks = player.textTracks();
        let metadataTrack;

        for (let i = 0; i < tracks.length; i++) {
          const track = tracks[i]; // find the metadata track that's labeled ads

          if (track.kind === "chapters") {
            metadataTrack = track;
          }
        }

        communicationMethod({
          chapters: metadataTrack,
        });
      });
    }

    if (event.data === "getCurrentVideoId") {
      if (config.media.length !== 0 && config.media.length === 1) {
        communicationMethod({ currentVideoId: config.media[0].id });
      } else {
        communicationMethod({
          currentVideoId: getCurrentVideo("playlist", player, config).id,
        });
      }
    }

    if (event.data === "pausePlayer") {
      player.pause();
    }

    if (event.data === "playPlayer") {
      player.play();
    }

    // for 'extractImagePlayer' we pass some data to the event we have to parse it before use it and detected type of event
    if (typeof event.data === 'string' && event.data !== 'getPlayer') {
      const data = JSON.parse(event.data)
      if (data.type === "extractImagePlayer") {

        const targetRatio = Number(data.ratio.split(':')[0]) / Number(data.ratio.split(':')[1]);
        const width = data.width;
        const height = width / targetRatio

        player.pause();
        const canvas = document.createElement("canvas");
        const context = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        canvas.style.display = "none";
        const videoElement = player.el().firstChild;

        context?.drawImage(
          videoElement as CanvasImageSource,
          0,
          0,
          width,
          height,
        );
        communicationMethod({
          currentImage: canvas.toDataURL('image/png'),
          target: 'parent'
        });
      }
    }

    if (event.data === "playlistPlayer") {
      // configurePlaylist(player, configuration.player);
    }

    if (event.data && event.data.name === "playertimeupdate") {
      player.currentTime(event.data.val);
    }
  };

  window.addEventListener("message", handleMessage, false);
}
