export function setDefaultSpeed(defaultSpeedValue: number): void {
  const elems = document.getElementsByClassName("vjs-speed");

  if (elems === null || elems.length === 0) {
    return;
  }

  document.getElementsByClassName("vjs-speed vjs-checked")[0].className =
    "vjs-speed";

  [].forEach.call(elems, (elem: HTMLElement) => {
    if (elem.innerText === defaultSpeedValue + "x") {
      elem.className += " vjs-checked";
      (
        document.getElementsByClassName("vjs-extend-speed")[0]
          .children[0] as HTMLElement
      ).innerText = elem.innerText;
    }
  });
}
