import { IPlayer } from "../models/player-config";

// this function define all custom style for player

export function customizePlayer(config: IPlayer, mediaNumber: number): void {

  let style = '';

  if (!config.control_color) {
    config.control_color = "#FFFFFF";
  }

  if (!config.controlbar_color) {
    config.controlbar_color = "#000000";
  }

  if (!config.control_active_color) {
    config.control_active_color = "#0098FF";
  }

  // logo styles

  if (config.logo_image !== void 0 && config.logo_image !== null) {
    const placement = config.logo_anchor === 'top_right' ? 'right' : config.logo_anchor === "top_left" ? 'left' : ""
    const offset = mediaNumber > 1 && placement === 'right' ? 35 : 0;


    style += `
      .vjs-logo {
        background-image: url(${config.logo_image}) !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: ${placement};
        width: ${config.logo_percentage}% !important;
        height: ${config.logo_percentage}% !important;
        ${placement}: ${config.logo_margin_horizontal}px !important;
        top: ${placement === 'left' && config.show_media_info ? config.logo_margin_vertical + 25 : config.logo_margin_vertical}px !important;
        z-index: 1 !important;
        margin-right: ${offset}px;
       }


      .vjs-logo img {
        display: none !important;
      }
    `
  }


  style += `

    .vjs-control-bar {
      background-color:${config.controlbar_color}!important
    }

    .video-js .vjs-big-play-button:hover {
      background-color:${config.control_active_color} !important
    }

    .vjs-control-bar .vjs-control span {
      color:${config.control_color} !important
    }

    .no-touch .video-js .vjs-button:hover {
      background-color: ${config.control_active_color} !important
    }

    .vjs-control-bar .vjs-load-progress {
      background-color: ${convertColorToRGB(config.control_active_color, 0.5)} !important
    }

    .video-js .vjs-progress-control .vjs-play-progress {
      border-radius: 4px;
      background-color:${convertColorToRGB(config.control_active_color, 1)} !important
    }

    .video-js .vjs-volume-level {
      background-color: ${convertColorToRGB(config.control_active_color, 1)} !important
    }
  `

  // controls button display

  if (!config.show_controls) {
    style += `
      .vjs-control-bar {
        display: none !important;
       }
      .vjs-has-started .vjs-control-bar {
        display: none !important;
      }
    `
  }

  if (!config.show_audio) {
    style += ".video-js .vjs-volume-panel { display: none !important; }";
  }

  if (!config.enable_embed_code) {
    style += ".embed-code { display: none !important; }";
  }

  if (!config.show_duration) {
    style += ".vjs-time-control { display: none !important; }";
  }

  if (!config.show_fullscreen) {
    style += ".vjs-fullscreen-control { display: none !important; }";
  }

  if (!config.show_media_info) {
    style += ".vjs-ttl, .vjs-dsc  { display: none !important; }";
    style += ".vjs-icon { max-width: 15% !important;}";
  } else {
    style += ".vjs-info-top {background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.6))};"
  }

  if (!config.show_progression) {
    style += ".vjs-progress-holder { display: none; }";
  }

  if (!config.show_speed) {
    style += ".vjs-extend-speed { display: none !important; }";
  }

  if (!config.show_replay) {
    style += ".vjs-replay-button { display: none !important; }";
    style += ".vjs-play-control.vjs-ended { display: none !important; }";
  }

  const head = document.head || document.getElementsByTagName("head")[0];
  const styleNode = document.createElement("style");

  styleNode.innerHTML = style;
  head.appendChild(styleNode);
}

function convertColorToRGB(color: string, opacity: number): string | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? `rgba(
      ${parseInt(result[1], 16)},
      ${parseInt(result[2], 16)},
      ${parseInt(result[3], 16)},
      ${opacity})`
    : null;
}
